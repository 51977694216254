<template>
  <div class="">
    <div class="">
      <div class="page-banner">
        <img :src="this.getSiteProps('clubinnerpages.headerbanner')" style="width:100%;" alt="page-banner">
        <div class="page-title">
          <h5 class="text-white">The Water Skier Magazine</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 mt-4  pb-3">
          <div class="card card-body pt-3 pb-3">
            <img src="/dist/img/TWS_Magazine Header.png" style="width:100%;" alt="page-banner">
            <h5 class="mt-4 mb-2">The Water Skier Magazine</h5>
            <p class="mt-1">The Water Skier, the nation’s premier water skiing magazine, is the official publication of
              USA Water Ski & Wake Sports. Published continuously since 1952, longer than any other publication
              of its kind, The Water Skier is the source to which water skiers turn for accurate information
              and knowledge about the sport.</p>
            <p>For questions about The Water Skier or for story ideas contact Scott Atkinson at satkinson@usawaterski.org.</p>
            <p>To view and download archived PDFs of The Water Skier magazine from October 1951-September 2009,&nbsp;<a target="_blank" href="https://www.usawaterski.org/TheWaterSkierArchives/ArchivesHome.html">click here.</a></p>
            <h6 class="mt-3 text-danger"><strong>Archive of Issues:</strong></h6>
            <div class="row">
              <div class="col-md-6 pl-0">
                <ul class="listofdynamiclinks">

                  <li v-for="(dynamicLink, index) in dynamicLinks" :key="index">
                    <a target="_balnk" :href="dynamicLink.url">{{ dynamicLink.title }}</a>
                  </li>

                </ul>
              </div>
              <div class="col-md-6">
                <ul class="listofdynamiclinks">

                  <li v-for="(dynamicLink, index) in dynamicLinksRight" :key="index">
                    <a target="_balnk" :href="dynamicLink.url">{{ dynamicLink.title }}</a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 status_bar mt-4">
          <div class="right-sidebarlink">
            <pagesidebar :links="this.getSiteProps('memberdashboard.links')"></pagesidebar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import pagesidebar from "../components/PageSidebar";
import axios from 'axios';

export default {

  name: "drivers-program",
  components: {pagesidebar},
  data() {
    return {
      dynamicLinks: null,
      dynamicLinksRight:null,
      prop_links:null
    }
  },
    updated(){
        this.prop_links = this.getSiteProps('memberdashboard.links');
    },
    mounted(){
      this.getMagazinesLink();
    },
    methods:{
    splitIntoChunk(arr, chunk) {
      while(arr.length > 0) {
          this.dynamicLinks = arr.splice(0, chunk);
          this.dynamicLinksRight = arr.splice(0, chunk);
      }
    },
    getMagazinesLink(){
                axios.get(this.basePath+'api/magazine_links', { headers:this.adminHeaders })
                    .then((res) => {
                      let dynamicL = res.data.data;
                      let count = ((dynamicL.length)/2)+1;
                      this.splitIntoChunk(dynamicL, count);
                    })
                    .catch((error) => {
                        console.log('Error:'+ error);
                        return false;
                    }).finally(() => {
                });
        }
    }
}

</script>